






























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import ManageCourierController from '@/app/ui/controllers/ManageCourierController'
import ManageCaptainController from '@/app/ui/controllers/ManageCaptainController'
import { Utils } from '@/app/infrastructures/misc'
import { ManageCourierAdjustSaldo } from '@/domain/entities/ManageCourier'
import dayjs from 'dayjs'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface IOptions {
  label: string
  value: string | number
}

interface IForm {
  courier: IOptions
  letterCodes: IOptions[]
  startAt: string
  endAt: string
  status: string
}

interface IParams {
  page: number
  perPage: number
  q: string
  sortBy: string
  driverType: string
  courierStatus: string
}

interface IValidation {
  [k: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    TextInput,
    DateTimePicker,
    MultiselectV2,
    ModalConfirm,
    ModalSuccess,
  },
})
export default class CreateManageCaptain extends Vue {
  controller = ManageCaptainController
  mcController = ManageCourierController
  routeController = routeController
  modalConfirmVisible = false
  modalSuccessVisible = false
  isFormChanged = false

  courierList: Array<{ label: string; value: number }> = []
  letterCodeOptions: Array<IOptions> = []

  form: IForm = {
    courier: this.courierList[-1],
    letterCodes: this.letterCodeOptions,
    startAt: '',
    endAt: '',
    status: '',
  }

  parameters: IParams = {
    page: 1,
    perPage: 10,
    q: '',
    sortBy: 'ASC',
    driverType: 'KVP',
    courierStatus: '',
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        courier: { required },
        letterCodes: { required },
        startAt: { required },
        endAt: { required },
      },
    }
  }

  created(): void {
    this.fetchOriginList()
    this.fetchCourier()
  }

  get courierParams(): Record<string, string | number> {
    return { ...this.parameters }
  }

  get isSubmitButtonDisabled(): boolean {
    return !(
      this.form.courier &&
      this.form.letterCodes &&
      this.form.startAt &&
      this.form.endAt
    )
  }

  private fetchCourier(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    this.mcController.getManageCourierListAdjustSaldo(this.courierParams)
  }

  private fetchOriginList(): void {
    this.routeController.getLetterCodes()
  }

  private onSearchOrigin(query: string): void {
    const res: Array<IOptions> = []

    if (query === '') {
      this.letterCodeOptions = routeController.letterCodes.map((route) => {
        return {
          label: <string>route.lc,
          value: <string>route.lc
        }
      })
    }

    routeController.letterCodes.forEach(route => {
      if ((<string>route.lc).toUpperCase().includes(query.toUpperCase())) {
        res.push({
          label: <string>route.lc,
          value: <string>route.lc
        })
      }
    })

    this.letterCodeOptions = res
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchCourier(true)
  }, 400)

  private onDropdownSearch(query: string): void {
    this.parameters.q = query
    this.onFilterList()
  }

  private onSubmit(): void {
    this.modalConfirmVisible = false

    if (!this.$v.form.$invalid) {
      const payload = {
        courier_id: Number(this.form.courier.value),
        letter_codes: this.form.letterCodes.map(item => String(item.value)),
        sk_start_date: this.form.startAt,
        sk_end_date: this.form.endAt,
      }
      this.controller.create(payload)
    }
  }

  private onBack(): void {
    this.$router.push(`/payroll/manage-captain`)
  }

  private onCheckStatus(): void {
    this.isFormChanged = true

    if (this.form.startAt && this.form.endAt) {
      const beforeToday = dayjs().isBefore(dayjs(this.form.endAt))
      const afterToday = dayjs().isAfter(dayjs(this.form.startAt))
      const isStartCurrentSame = dayjs().isSame(dayjs(this.form.startAt), 'day')
      const isEndCurrentSame = dayjs().isSame(dayjs(this.form.endAt), 'day')

      if (isStartCurrentSame && isEndCurrentSame) {
        this.form.status = 'Active'
        return
      }

      if (beforeToday && afterToday) {
        this.form.status = 'Active'
      } else {
        this.form.status = 'Inactive'
      }
    } else {
      this.form.status = ''
    }
  }

  @Watch('routeController.letterCodes')
  setOriginData(data: LetterCode[]): void {
    this.letterCodeOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })
  }

  @Watch('mcController.manageCourierAdjustSaldoData')
  setManageCourierData(data: ManageCourierAdjustSaldo[]): void {
    const result: Array<{ label: string; value: number }> = []

    data.forEach((courier: ManageCourierAdjustSaldo) => {
      const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
      const value = courier.courierId

      if (label && value) {
        result.push({
          label: label,
          value: value,
        })
      }
    })

    this.courierList = result
  }

  @Watch('controller.isAddManageCaptainSuccess')
  setAddManageCaptainSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.fetchCourier()
      this.controller.setAddManageCaptainSuccess(false)
    }
  }
}
