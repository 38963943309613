















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import CloseLine from '@/app/ui/assets/close_line.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import CheckBoxUnchecked from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxChecked from "@/app/ui/assets/check_box_checked.vue";
import {REGEX_HTML_TAG} from "@/app/infrastructures/misc/Constants/regex";

interface Options {
  label: string
  value: string | number
  title?: string
}

@Component({
  components: {
    CaretDownIcon,
    CloseLine,
    CheckedIcon,
    CheckBoxUnchecked,
    CheckBoxChecked,
  },
})
export default class MultiselectV2 extends Vue {
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: String, default: 'tags' }) variant!: 'tags' | 'outside'
  @Prop({ type: Array, default: () => [] }) value!: Options[]
  @Prop({ type: Array, default: () => [] }) options!: Options[]
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Number, default: 3 }) tagsDisplayAmount!: number
  @Prop({ type: Number, default: 4 }) outsideDisplayAmount!: number
  @Prop({ type: String, default: 'checked' }) iconSelected!: 'checked' | 'checkbox'

  keyword = ''
  active = false
  isShowChips = false
  outZone = false
  onDrag = false
  mouseEnterTags = false

  @Watch('keyword')
  private onSearch(keyword: string): void {
    this.$emit('search', keyword)
  }

  private getLabel(option: Options): string {
    let label = option.label
    if (option.label.lastIndexOf('-') >= 0) {
      label = option.label.substr(0, option.label.lastIndexOf('-')).trim()
    }
    if (option.title && option.title !== '') {
      label = option.title
    }
    label = label.replace(/<[^>]*>?/gm, '')
    return label
  }

  private isSelected(option: Options): boolean {
    return this.value?.some(item => {
      return item.value === option.value
    }) as boolean
  }

  private onFocus(): void {
    this.active = true
    this.isShowChips = !this.isShowChips

    const listTags = document.querySelectorAll('.multiselect-tags')
    listTags.forEach((item: Node, idx: number) => {
      const elTags = item.parentElement?.querySelector('.multiselect-tags')
      if (idx > 2 && elTags) {
        elTags.classList.add('hasExpanded')
      }
    })

    this.$nextTick(() => {
      const input = this.$refs.input as HTMLInputElement
      input.focus()
    })
    this.$emit('open')
  }

  private onBlur(): void {
    this.isShowChips = false
    this.active = false
    this.keyword = ''
    if (!this.mouseEnterTags && this.variant === 'tags') {
      this.isShowChips = false
      this.onDrag = false
    }
    this.$emit('close')
  }

  private remove(option: Options): void {
    this.$emit(
      'input',
      this.value?.filter(val => {
        return val.value !== option.value
      })
    )
  }

  private onExpanded(event: Event): void {
    if (this.disabled) return
    const currentTarget = <HTMLDivElement>event.currentTarget
    currentTarget.classList.toggle('hasExpanded')
  }

  private onDelete(option: Options): void {
    if (this.disabled) return
    return this.remove(option)
  }

  private onSeeAll(): void {
    this.isShowChips = !this.isShowChips
  }

  private selectOptions(value: Options): void {
    if (this.isSelected(value)) {
      return this.remove(value)
    }

    const selected = this.value as Options[]
    selected.push(value)

    this.$emit('input', selected)
  }

  private onDragStart(
    { dataTransfer }: { dataTransfer: DataTransfer },
    index: number
  ): void {
    if (this.disabled) return
    dataTransfer.dropEffect = 'move'
    dataTransfer.effectAllowed = 'move'
    dataTransfer.setData('index', String(index))
    this.onDrag = true
  }

  private onDrop(
    { dataTransfer }: { dataTransfer: DataTransfer },
    index: number
  ): void {
    if (this.disabled) return
    const indexId = dataTransfer.getData('index')
    const selectedItem = this.value[parseInt(indexId)]
    const newArrangement = this.value.filter(
      val => val.value !== selectedItem.value
    )
    newArrangement.splice(index, 0, selectedItem)
    newArrangement.forEach((item, idx) => {
      this.value[idx] = item
    })

    // For Update UI
    this.active = true
    this.active = false
  }

  private mouse(): void {
    this.outZone = true
  }

  private mouseLeave(): void {
    this.outZone = false
  }

  private handleMouseTags(): void {
    this.mouseEnterTags = true
  }

  private handleMouseLeaveTags(): void {
    this.mouseEnterTags = false
  }
}
