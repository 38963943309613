var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-56"},[_vm._m(0),_c('div',{staticClass:"w-3/5 mb-8"},[_vm._m(1),_c('DropdownSelect',{attrs:{"required":true,"searchable":true,"allowEmpty":false,"options":_vm.courierList,"isError":_vm.$v.form.courier.$invalid && _vm.isFormChanged,"placeholder":"Search Courier"},on:{"search":function (e) { return _vm.onDropdownSearch(e); },"input":function () {
          _vm.isFormChanged = true
        }},model:{value:(_vm.form.courier),callback:function ($$v) {_vm.$set(_vm.form, "courier", $$v)},expression:"form.courier"}}),(_vm.$v.form.courier.$invalid && _vm.isFormChanged)?_c('span',{staticClass:"text-xs text-red-4"},[_vm._v(" Wajib diisi ")]):_vm._e()],1),_c('div',{staticClass:"w-3/5 mb-8"},[_vm._m(2),_c('MultiselectV2',{staticClass:"w-full",attrs:{"placeholder":"Masukkan 3LC","variant":"tags","tagsDisplayAmount":5,"options":_vm.letterCodeOptions},on:{"search":function (e) { return _vm.onSearchOrigin(e); },"input":function () {
          _vm.isFormChanged = true
        }},model:{value:(_vm.form.letterCodes),callback:function ($$v) {_vm.$set(_vm.form, "letterCodes", $$v)},expression:"form.letterCodes"}}),(_vm.$v.form.letterCodes.$invalid && _vm.isFormChanged)?_c('span',{staticClass:"text-xs text-red-4"},[_vm._v(" Wajib diisi ")]):_vm._e()],1),_c('div',{staticClass:"w-3/5 mb-8"},[_vm._m(3),_c('DateTimePicker',{attrs:{"valueFormat":"date","withIcon":"","noButtonNow":"","color":"#CA0000","label":"Tanggal Mulai","formatted":"DD MMM YYYY","error":_vm.$v.form.startAt.$invalid && _vm.isFormChanged,"maxDate":_vm.form.endAt,"autoClose":true},on:{"input":_vm.onCheckStatus},model:{value:(_vm.form.startAt),callback:function ($$v) {_vm.$set(_vm.form, "startAt", $$v)},expression:"form.startAt"}}),(_vm.$v.form.startAt.$invalid && _vm.isFormChanged)?_c('span',{staticClass:"text-xs text-red-4"},[_vm._v(" Wajib diisi ")]):_vm._e()],1),_c('div',{staticClass:"w-3/5 mb-8"},[_vm._m(4),_c('DateTimePicker',{attrs:{"valueFormat":"date","withIcon":"","noButtonNow":"","color":"#CA0000","label":"Tanggal Akhir","formatted":"DD MMM YYYY","error":_vm.$v.form.endAt.$invalid && _vm.isFormChanged,"minDate":_vm.form.startAt,"autoClose":true},on:{"input":_vm.onCheckStatus},model:{value:(_vm.form.endAt),callback:function ($$v) {_vm.$set(_vm.form, "endAt", $$v)},expression:"form.endAt"}}),(_vm.$v.form.endAt.$invalid && _vm.isFormChanged)?_c('span',{staticClass:"text-xs text-red-4"},[_vm._v(" Wajib diisi ")]):_vm._e()],1),_c('div',{staticClass:"w-3/5 mb-8"},[_vm._m(5),_c('TextInput',{attrs:{"disabled":true},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('div',{staticClass:"w-full flex flex-row"},[_c('Button',{staticClass:"w-42-a py-2 px-4 text-xs mr-5",attrs:{"buttonStyle":"outline"},on:{"click":_vm.onBack}},[_c('span',[_vm._v("Kembali")])]),_c('Button',{staticClass:"w-42-a py-2 px-4 text-xs",attrs:{"disabled":_vm.isSubmitButtonDisabled},on:{"click":function () {
          _vm.modalConfirmVisible = true
        }}},[_c('span',[_vm._v("Submit")])])],1),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmVisible,"title":"Konfirmasi Penambahan Data","description":"Pastikan data yang diisi sudah sesuai dengan data aslinya"},on:{"cancel":function () {
        _vm.modalConfirmVisible = false
      },"action":function () { return _vm.onSubmit(); }}}),_c('ModalSuccess',{attrs:{"visible":_vm.modalSuccessVisible,"title":"Data Berhasil Ditambahkan","description":""},on:{"close":function () { return _vm.onBack(); }}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row justify-between mt-1 mb-10"},[_c('h2',{staticClass:"font-bold text-gray-4"},[_vm._v("Tambah Data")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("Search Courier")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("3LC")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("SK Start Date")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('span',[_vm._v("SK End Date")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('span',{},[_vm._v("Status")])])}]

export { render, staticRenderFns }